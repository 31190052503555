import { GinServicePromiseClient } from "@lctech-tw/post-proto/dist/js/post/gin/gin_grpc_web_pb";
import * as postPb from "@lctech-tw/post-proto/dist/js/post/gin/gin_pb";
import {
  Candidate,
  Creator,
  PostDetail,
  SettleItem,
} from "@lctech-tw/post-proto/dist/js/external/atom/atom_pb";
import {
  PostStatus,
  PublishType,
} from "@lctech-tw/post-proto/dist/js/external/enums/enums_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

const service = () => {
  const client = new GinServicePromiseClient(
    process.env.VUE_APP_POST_HOST || ""
  );
  const enableDevTools =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__GRPCWEB_DEVTOOLS__ ||
    (() => {
      return;
    });
  enableDevTools([client]);
  return client;
};

const getPostList = async (
  payload: {
    page: number;
    limit: number;
    from?: Timestamp | undefined;
    to?: Timestamp | undefined;
    postNumber?: string;
    creator?: Creator;
    publishType: boolean | null;
    status: PostStatus;
    starObjectIds?: string[];
  },
  ginToken: string
): Promise<postPb.GetPostListRes> => {
  const req: postPb.GetPostListReq = new postPb.GetPostListReq();
  let res: postPb.GetPostListRes;
  req.setGinToken(ginToken);
  req.setPage(payload.page);
  req.setLimit(payload.limit);
  req.setPostStartAt(payload.from);
  req.setPostEndAt(payload.to);
  payload.postNumber && req.setPostNo(payload.postNumber);
  payload.creator && req.setCreator(payload.creator);
  if (payload.publishType === null) {
    req.setPublishType(PublishType.ALL);
  } else {
    req.setPublishType(
      payload.publishType ? PublishType.PUBLIC : PublishType.PRIVATE
    );
  }
  payload.starObjectIds && req.setStarObjectIdList(payload.starObjectIds);
  req.setStatus(payload.status);
  try {
    res = await service().getPostList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const setPostStatus = async (
  payload: {
    postId: number;
    status: PostStatus;
  },
  ginToken: string
): Promise<postPb.UpdatePostStatusRes> => {
  const req: postPb.UpdatePostStatusReq = new postPb.UpdatePostStatusReq();
  let res: postPb.UpdatePostStatusRes;
  req.setGinToken(ginToken);
  req.setPostId(payload.postId);
  req.setStatus(payload.status);
  try {
    res = await service().updatePostStatus(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const createPost = async (
  payload: {
    postDetail: PostDetail;
  },
  ginToken: string
): Promise<postPb.CreatePostRes> => {
  const req: postPb.CreatePostReq = new postPb.CreatePostReq();
  let res: postPb.CreatePostRes;
  req.setGinToken(ginToken);
  req.setInfo(payload.postDetail);
  try {
    res = await service().createPost(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

// TODO
// const updatePost = async (
//   payload: {
//     postDetail: PostDetail;
//   },
//   ginToken: string
// ): Promise<postPb.CreatePostRes> => {
//   const req: postPb.CreatePostReq = new postPb.CreatePostReq();
//   let res: postPb.CreatePostRes;
//   req.setGinToken(ginToken);
//   req.setInfo(payload.postDetail);
//   try {
//     res = await service().createPost(req);
//   } catch (e) {
//     return Promise.reject(e);
//   }
//   return res;
// };

const getActivityList = async (
  ginToken: string
): Promise<postPb.GetActivityListRes> => {
  const req: postPb.GetActivityListReq = new postPb.GetActivityListReq();
  let res: postPb.GetActivityListRes;
  req.setGinToken(ginToken);
  try {
    res = await service().getActivityList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPostItemList = async (
  ginToken: string
): Promise<postPb.GetPostItemListRes> => {
  const req: postPb.GetPostItemListReq = new postPb.GetPostItemListReq();
  let res: postPb.GetPostItemListRes;
  req.setGinToken(ginToken);
  try {
    res = await service().getPostItemList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getCurrencyList = async (
  ginToken: string
): Promise<postPb.GetCurrencyListRes> => {
  const req: postPb.GetCurrencyListReq = new postPb.GetCurrencyListReq();
  let res: postPb.GetCurrencyListRes;
  req.setGinToken(ginToken);
  try {
    res = await service().getCurrencyList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPostDetail = async (
  id: number,
  ginToken: string
): Promise<postPb.GetPostDetailRes> => {
  const req: postPb.GetPostDetailReq = new postPb.GetPostDetailReq();
  let res: postPb.GetPostDetailRes;
  req.setGinToken(ginToken);
  req.setPostId(id);
  try {
    res = await service().getPostDetail(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const uploadFile = async (
  payload: {
    fileName: string;
  },
  ginToken: string
): Promise<postPb.UploadFileToGCSRes> => {
  const req: postPb.UploadFileToGCSReq = new postPb.UploadFileToGCSReq();
  let res: postPb.UploadFileToGCSRes;
  req.setFileName(payload.fileName);
  // TODO
  req.setPostId("temp");
  req.setGinToken(ginToken);
  try {
    res = await service().uploadFileToGCS(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const getPostCandidateList = async (
  payload: {
    postId: number;
  },
  ginToken: string
): Promise<postPb.GetPostEntryListRes> => {
  const req: postPb.GetPostEntryListReq = new postPb.GetPostEntryListReq();
  let res: postPb.GetPostEntryListRes;
  req.setGinToken(ginToken);
  req.setLimit;
  req.setPage;
  req.setPostId(payload.postId);
  try {
    res = await service().getPostEntryList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const updatePostCandidateList = async (
  payload: {
    postId: number;
    candidateList: Candidate[];
  },
  ginToken: string
): Promise<postPb.UpdatePostEntryListRes> => {
  const req: postPb.UpdatePostEntryListReq =
    new postPb.UpdatePostEntryListReq();
  let res: postPb.UpdatePostEntryListRes;
  req.setCandidatesList(payload.candidateList);
  req.setPostId(payload.postId);
  req.setGinToken(ginToken);
  try {
    res = await service().updatePostEntryList(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const createPostCheckInLog = async (
  payload: {
    postId: number;
    starObjectId: string;
  },
  ginToken: string
): Promise<postPb.CreatePostCheckInLogRes> => {
  const req: postPb.CreatePostCheckInLogReq =
    new postPb.CreatePostCheckInLogReq();
  let res: postPb.CreatePostCheckInLogRes;
  req.setGinToken(ginToken);
  req.setPostId(payload.postId);
  req.setStarObjectId(payload.starObjectId);
  try {
    res = await service().createPostCheckInLog(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const createPostSettleLog = async (
  payload: {
    postId: number;
    settleList: SettleItem[];
  },
  ginToken: string
): Promise<postPb.CreatePostSettleLogRes> => {
  const req: postPb.CreatePostSettleLogReq =
    new postPb.CreatePostSettleLogReq();
  let res: postPb.CreatePostSettleLogRes;
  req.setGinToken(ginToken);
  req.setPostId(payload.postId);
  req.setInfosList(payload.settleList);
  try {
    res = await service().createPostSettleLog(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

const createScanToken = async (
  payload: {
    postId: number;
  },
  ginToken: string
): Promise<postPb.CreateScanTokenRes> => {
  const req: postPb.CreateScanTokenReq = new postPb.CreateScanTokenReq();
  let res: postPb.CreateScanTokenRes;
  req.setGinToken(ginToken);
  req.setPostId(payload.postId);
  try {
    res = await service().createScanToken(req);
  } catch (e) {
    return Promise.reject(e);
  }
  return res;
};

export const apiPost = {
  getPostList,
  setPostStatus,
  createPost,
  getActivityList,
  getPostItemList,
  getCurrencyList,
  getPostDetail,
  uploadFile,
  getPostCandidateList,
  updatePostCandidateList,
  createPostCheckInLog,
  createPostSettleLog,
  createScanToken,
};
